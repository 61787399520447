<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="printArticle_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <print-article-filter></print-article-filter>
      <div class="card">
        <div class="card-body">
          <preloader v-if="callingAPI"></preloader>
          <data-table
            :data="printArticles"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import config from '../../config'
import Preloader from '../preloader/Preloader'
import PrintArticleFilter from './PrintArticleFilter'
import DataTable from '../shared/DataTable'
import ButtonCreate from '../shared/ButtonCreate'

export default {
  name: 'PrintArticleList',
  data () {
    return {
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('printArticle.id'),
          title: this.$t('printArticle.title'),
          publicationExpanded: this.$t('printArticle.publication'),
          native: this.$t('printArticle.native'),
          type: this.$t('printArticle.type'),
          quality: this.$t('printArticle.quality'),
          source: this.$t('printArticle.source'),
          sharedUsers: this.$t('printArticle.sharedUsers')
        },
        render: {
          native: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          sharedUsers: (userIDs) => {
            return userIDs.map(userID => {
              const user = this.$store.getters['user/userById'](userID)
              if (user) {
                return user.username
              }

              return 'n/a'
            }).join(', ')
          },
          publicationExpanded: publicationExpanded => {
            return publicationExpanded.printTitle + ' - ' + publicationExpanded.title
          }
        },
        actions: {
          edit: 'printArticle_edit'
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    printArticles () {
      return this.$store.getters['printArticle/list']
    },
    totalCount () {
      return this.$store.getters['printArticle/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['printArticle/totalCount'] / config.defaults.list.limit)
    }
  },
  components: {
    appModuleView: ModuleView,
    Preloader: Preloader,
    PrintArticleFilter: PrintArticleFilter,
    DataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getPrintArticles () {
      this.$store.dispatch('printArticle/fetch')
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('printArticle/setPage', page)
      this.getPrintArticles()
    }
  },
  mounted () {
    this.page = this.$store.getters['printArticle/page']
    this.getPrintArticles()
  }
}
</script>
